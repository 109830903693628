import { TABLE } from '/app.config';

const { view } = TABLE.icons;

const table = {
  data() {
    return {
      columns: [
        {
          width: 150,
          prop: 'createName',
          label: '评价人',
          noUser: true
        },
        {
          width: 180,
          prop: 'score',
          label: '评分',
          noUser: true,
          isScore: true
        },
        {
          width: 300,
          prop: 'createTime',
          label: '评价时间',
          time: true,
          noUser: true
        },
        {
          width: 800,
          prop: 'content',
          label: '评价内容',
          noUser: true,
          tooltip: true
        }
      ],
      operations: {
        handles: [
          {
            icon: view,
            name: '查看',
            handle: this.view
          }
        ]
      }
    };
  }
};
export { table };
