<template>
  <div>
    <div class="main-box-white block-box">
      <section class="latest-status" :class="{ active: score == '' }" @click="searchData('')">
        <div>
          <span class="iconfont icon-quanbu status-wait"></span>
        </div>
        <div>
          <span class="status-num">{{
            evaluateAllNum.verySatisfiedNumber +
            evaluateAllNum.satisfiedNumber +
            evaluateAllNum.commonNumber +
            evaluateAllNum.dissatisfiedNumber +
            evaluateAllNum.veryDissatisfiedNumber
          }}</span>
          <p>全部</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: score == '2' }" @click="searchData('2')">
        <div>
          <span class="iconfont icon-feichangmanyi status-wait"></span>
        </div>
        <div>
          <span class="status-num">{{ evaluateAllNum.verySatisfiedNumber || 0 }}</span>
          <p>非常满意</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: score == '1' }" @click="searchData('1')">
        <div>
          <span class="iconfont icon-manyi status-success"></span>
        </div>
        <div>
          <span class="status-num">{{ evaluateAllNum.satisfiedNumber || 0 }}</span>
          <p>满意</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: score == '0' }" @click="searchData('0')">
        <div>
          <span class="iconfont icon-yiban status-sub"></span>
        </div>
        <div>
          <span class="status-num">{{ evaluateAllNum.commonNumber || 0 }}</span>
          <p>一般</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: score == '-1' }" @click="searchData('-1')">
        <div>
          <span class="iconfont icon-bumanyi status-no"></span>
        </div>
        <div>
          <span class="status-num">{{ evaluateAllNum.dissatisfiedNumber || 0 }}</span>
          <p>不满意</p>
        </div>
      </section>
      <section class="latest-status" :class="{ active: score == '-2' }" @click="searchData('-2')">
        <div>
          <span class="iconfont icon-feichangbumanyi status-fail"></span>
        </div>
        <div>
          <span class="status-num">{{ evaluateAllNum.veryDissatisfiedNumber || 0 }}</span>
          <p>非常不满意</p>
        </div>
      </section>
    </div>
    <div class="main-box-white">
      <list-container :grid="false" placeholder="请输入关键字" :pagination="pagination" :isAdd="false" :searchInput="false" @fetch="loadData" @titleSearch="titleSearch($event)">
        <template v-slot:filter>
          <div class="select-box">
            <el-date-picker
              style="margin-right: 20px"
              v-model="dataRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dateChange"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-select v-model="orderBelongDept" placeholder="请选择部门">
              <el-option value="" label="全部">全部</el-option>
              <el-option v-for="item in departmentList" :key="item.id" :label="item.orderDeptName" :value="item.orderDeptName"> </el-option>
            </el-select>
            <el-button icon="el-icon-search" style="margin-left: 20px" type="primary" @click="loadData"></el-button>
            <!-- <el-select style="margin-left: 20px" v-model="problemType" placeholder="请选择问题分类">
              <el-option v-for="item in problemTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-select style="margin-left: 20px" v-model="status" placeholder="请选择问题状态">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select> -->
          </div>
        </template>
        <template v-slot:table>
          <base-table :data="data" :columns="columns" :isCheckTwo="true" :operations="operations" :multi="false" />
        </template>
      </list-container>
    </div>
  </div>
</template>

<script>
import listContainer from '@/components/list-container/list-container';
import baseTable from '@/components/table/base-table/base-table';
import { TABLE } from '/app.config';
import { table } from './mixin';
export default {
  name: 'evaluate',
  mixins: [table],
  data() {
    return {
      data: [],
      pagination: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      },
      dataRange: '',
      orderBelongDept: '',
      createTimeStart: '',
      createTimeEnd: '',
      score: '',
      departmentList: [],
      evaluateAllNum: {
        verySatisfiedNumber: 0,
        satisfiedNumber: 0,
        commonNumber: 0,
        dissatisfiedNumber: 0,
        veryDissatisfiedNumber: 0
      }
    };
  },
  activated() {
    this.loadData();
    this.getDepartmentList();
    this.getCompleteNum();
  },
  methods: {
    async loadData({ currentPage, currentPageSize } = {}) {
      this.pagination.currentPage = currentPage || this.pagination.currentPage;
      this.pagination.pageSize = currentPageSize || this.pagination.pageSize;

      const params = {
        orderBelongDept: this.orderBelongDept,
        createTimeStart: this.createTimeStart,
        createTimeEnd: this.createTimeEnd,
        score: this.score,
        page: this.pagination.currentPage,
        limit: this.pagination.pageSize
      };
      this.loadDataAll(params);
    },
    async loadDataAll(params) {
      const { data, count } = await this.$apis.workOrder.evaluate(params);
      this.data = data;
      this.pagination.total = count;
    },
    async getCompleteNum() {
      const { data } = await this.$apis.workOrder.evaluateNum();
      this.evaluateAllNum = data;
      // console.log(data);
    },
    searchData(val) {
      this.score = val;
      this.loadData();
    },
    // 详情
    view({ orderId }) {
      let id = orderId;
      this.$router.push({ name: 'evaluate_detail', params: { id } });
    },
    dateChange(val) {
      if (val) {
        this.createTimeStart = val[0];
        this.createTimeEnd = val[1];
      } else {
        this.createTimeStart = '';
        this.createTimeEnd = '';
      }
    },
    //获取部门列表
    async getDepartmentList() {
      const { data } = await this.$apis.setDepartment.list();
      this.departmentList = data;
    },
    titleSearch(searchText) {
      this.searchText = searchText;
      // this.searchParams = {};
      this.loadData();
    }
  },
  watch: {
    // searchText() {
    //   this.loadData();
    // }
  },
  components: {
    listContainer,
    baseTable
  }
};
</script>

<style lang="scss" scoped>
.block-box {
  display: flex;
  margin-bottom: 10px;
}
.select-box {
  display: flex;
  align-items: center;
}
.latest-status {
  width: 184px;
  height: 64px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-right: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(18, 18, 18, 0.16);
  }
  &.active {
    box-shadow: 0px 2px 10px 0px rgba(18, 18, 18, 0.16);
  }
  .status-num {
    font-size: 20px;
    display: block;
    text-align: right;
    font-family: YouSheBiaoTiHei;
  }
  p {
    color: #5a5a5a;
    font-size: 14px;
    line-height: 1;
  }
  .iconfont {
    font-size: 30px;
  }
}
</style>
